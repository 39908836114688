import router from './router'
// import store from './store'

router.beforeEach(async(to, from, next) => {
  const isLogin = localStorage.getItem('mtoken')!==null&&localStorage.getItem('mtoken')!=='';

  if (to.name !== 'Login' && !isLogin){
    next({ name: 'Login' })
  } 
  else {
    next()
  }

})

router.afterEach(() => {

})