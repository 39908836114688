import axios from 'axios'
import { message } from 'ant-design-vue';
// import vue from './main.js'
// import store from '@/store'
import router from './router'


// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL:'http://saleapi.uniceramics.com.cn/v1',
  baseURL:'https://saleapi.uniceramics.com.cn/v1',

  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})
// console.log(process.env.VUE_APP_BASE_API);
// request interceptor request拦截器
service.interceptors.request.use(
  config => {
    // do something before request is sent

    // if (store.getters.token) {
      // console.log("request拦截器");
      // 让每个请求携带token--['X-Token']为自定义key
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['X-Token'] = getToken()
    // }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    // console.log(response);
    if(response.data.errcode===401 || response.errcode===401){
      console.log("401 登录失效");
      message.error({content:"token失效，请重新登录！"});
      setTimeout(()=>{
        router.replace({ path: '/login' });
        // location.reload();
      },100)
    }else{

        if(response.errcode===200){
          return response;
        }
        else if(response.status===200){
          return response.data;
        }else{
          message.error({content:response.errmsg || 'Error'});
        }

    }
  },
  error => {
    message.error({content:"网络超时，请稍后再试！"});
    console.log('err' + error)
    return Promise.reject(error)
  }
)

export default service
