<template>
  <div>
  	<router-view v-slot="{ Component }">
     	<div>
	        <transition name="fade-transform" mode="out-in">
	          <component :is="Component" />
	        </transition>
        </div>
    </router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

// This starter template is using Vue 3 experimental <script setup> SFCs
// Check out https://github.com/vuejs/rfcs/blob/script-setup-2/active-rfcs/0000-script-setup.md
export default {
  name: 'App',
}
</script>

<style>
</style>